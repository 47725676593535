<template>
  <DialogPanel
    class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-screen-xl"
  >
    <ModalHeader title="Preview document" @close="$emit('dismiss')" />

    <div class="grid grid-cols-1 md:grid-cols-2 gap-5 p-5 bg-gray-50">
      <div class="md:hidden sticky top-4 z-10">
        <DocBox :doc="doc" />
        <div class="absolute bottom-1 -left-1 -right-1 -top-4 -z-10 bg-gray-50"></div>
      </div>
      <div class="order-last md:order-first">
        <DocBox class="mb-5 hidden md:block" :doc="doc" />
        <!-- TODO: Show warning here if type mismatch -->
        <div class="overflow-hidden bg-white shadow rounded-lg">
          <div class="px-4 py-5 sm:px-6 flex items-center justify-between">
            <h3 class="text-base font-semibold leading-6 text-gray-900">Document details</h3>
            <AppButton class="-my-2" v-if="!!doc.imageUrls" @clicked="download()"
              >Download</AppButton
            >
          </div>
          <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl class="sm:divide-y sm:divide-gray-200">
              <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">Document type</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <span
                    :data-test-id="`documents-view-All-tab`"
                    class="inline-flex mr-2 items-center rounded-full py-1 px-3 text-sm font-medium text-blue-700 bg-blue-200"
                  >
                    {{ $t(`docCategories.${doc?.categoryId}.name`) }}
                  </span>
                </dd>
              </div>
              <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">Validity</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 flex">
                  {{ format(new Date(doc.issueDate!), 'dd MMM yyyy') }}
                  <ArrowLongRightIcon class="w-5 mx-3" />
                  {{
                    !!doc.expiryDate
                      ? format(new Date(doc.expiryDate!), 'dd MMM yyyy')
                      : '(no expiry)'
                  }}
                </dd>
              </div>
              <div
                v-if="!!doc.documentRef"
                class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6"
              >
                <dt class="text-sm font-medium text-gray-500">Document number</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {{ doc.documentRef }}
                </dd>
              </div>
              <div
                v-if="!!doc.certificateIssuerId"
                class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6"
              >
                <dt class="text-sm font-medium text-gray-500">Document issuer</dt>
                <dd v-if="!!issuerList" class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {{ issuerList.get(doc.certificateIssuerId)?.name || doc.certificateIssuerId }}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <DocumentImages v-if="!!doc.imageUrls" :doc-id="doc.id" :image-urls="doc.imageUrls" />
      <div
        v-else
        class="flex items-center justify-center relative py-10 bg-gray-300 border border-gray-300 rounded-md overflow-hidden"
      >
        <img :src="BlurredDocImgSrc" class="absolute inset-0 h-full w-full object-contain" />
        <div class="relative flex flex-col items-center">
          <EyeSlashIcon class="h-10 w-10 mx-auto" />
          <div class="my-2 font-semibold text-center">Connect with Crewdentials to:</div>
          <div class="text-left">
            ✅ View full document<br />
            ✅ Verify with issuer<br />
            ✅ Collect extra data/docs<br />
          </div>
          <div class="mt-2 font-semibold">...and much more!</div>
          <a href="/login" target="_blank"
            ><AppButton class="mx-auto my-4">Sign up for free</AppButton></a
          >
          <div class="text-xs">No payment info required</div>
          <a
            href="https://www.crewdentials.com/solutions/business-workspace"
            target="_blank"
            class="text-xs font-semibold text-blue-600"
            >find out more</a
          >
        </div>
      </div>
    </div>
  </DialogPanel>
</template>

<script setup lang="ts">
import { DialogPanel } from '@headlessui/vue'
import type { DocumentData } from '@/types/types'
import ModalHeader from '@/components/ModalHeader.vue'
import DocBox from '@/components/DocBox.vue'
import { ArrowLongRightIcon, EyeSlashIcon } from '@heroicons/vue/24/outline'
import DocumentImages from './DocumentImages.vue'
import AppButton from './AppButton.vue'
import BlurredDocImgSrc from '@/assets/blurred-doc.png'
import { format } from 'date-fns'
import { useIssuerListQuery } from '@/queries/issuerList'
import downloadDocumentPdf from '@/helpers/downloadDocumentPdf'

const props = defineProps<{
  doc: DocumentData & { imageUrls: string[] }
}>()

const download = async () => {
  downloadDocumentPdf(props.doc.imageUrls, props.doc.name || 'crewdentials-export')
}

const { data: issuerList } = useIssuerListQuery(!!props.doc.certificateIssuerId)
</script>
